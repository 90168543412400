<template>
  <div class="metaContainer" id="metaContainer">

    <div class="scene3d-container" :id="'scene3dContainer' + id">
      <iframe frameborder="0" :id="'iframe' + id" wodth='1366' height="768" style="width:100%;height:100%" border='0' src="/unity/index.html"></iframe>
    </div>
    <div class="containerSdk" :id="'container' + id">
      <div class="container_box">
        <div class="headerSdk">
          <span style="float: left">白泽智能学伴</span>
          <span style="
              float: right;
              color: #333333;
              padding: 0px 6px;
              cursor: pointer;
            " class="closeBtn" :id="'closeBtn' + id">X</span>
          <span style="
              float: right;
              color: #333333;
              padding: 0px 6px;
              cursor: pointer;
            " class="closeBtns" :id="'closeBtns' + id">-</span>
          <div :id="'sound' + id" class="soundSDK" @click="sound = !sound" style="float: right; cursor: pointer">
            <unmute v-if="sound" />
            <mute v-if="!sound" />
          </div>
        </div>
        <ul class="contentSdk" id="contentSdk">

        </ul>
        <div class="footerSdk">
          <div class="talkSendContainer">
            <input type="text" v-model="message" placeholder="请输入问题" class="sendInput" :id="'sendTextInput' + id" />
            <div :id="'sendText' + id" class="btn">
              <sendbtn />
            </div>
          </div>
          <div :id="'voice' + id" class="metavoice" @click="voice = !voice">
            <voicedisable v-if="!voice" />
            <waveform v-else-if="userTalk && voice" />
            <voiceactive v-else-if="voice && !userTalk" />
          </div>
        </div>
      </div>
    </div>

    <!--    <div class="metatalkbox" :id="'metatalkbox' + id">
      <talkbg />

    </div>-->
    <div id="metabtnsBox">
      <div class="metabtns" id="metabtns">
        <div class="metabtn metastartBtn" :id="'Metabtn' + id">开启</div>
        <!--      <div class="metabtn metacloseBtn" :id="'closeBtn' + id">关闭</div>-->
      </div>
      <div class="sdkloading" id="loading">
        <div class="loader"></div>
      </div>
    </div>

  </div>
</template>

<script>
import talkbg from "./components/talk";
import sendbtn from "./components/svgs/sendbtn.vue";
import voicedisable from "./components/svgs/voicedisable.vue";
import voiceactive from "./components/svgs/voiceactive.vue";
import unmute from "./components/svgs/unmute.vue";
import mute from "./components/svgs/mute.vue";
import waveform from "./components/svgs/waveform.vue";
// import { randomString } from "./utils/myUtils";
export default {
  name: "metaVue",
  components: {
    talkbg,
    sendbtn,
    voiceactive,
    voicedisable,
    unmute,
    mute,
    waveform,
  },
  data() {
    return {
      //id: randomString(6),
      id: "Sdk",
      voice: true,
      message: "",
      sound: true,
      userTalk: false,
    };
  },
  mounted() {
    this.makeExpandingArea(document.getElementsByClassName("sendInput"));
  },
  methods: {
    makeExpandingArea(el) {
      let setStyle = function (el) {
        el.style.height = "auto";
        el.style.height = el.scrollHeight + "px";
        el.scrollTop = 0;
      };
      let delayedResize = function (el) {
        window.setTimeout(function () {
          setStyle(el);
        }, 0);
      };
      if (el.addEventListener) {
        el.addEventListener(
          "input",
          function () {
            setStyle(el);
          },
          false
        );
        setStyle(el);
      } else if (el.attachEvent) {
        el.attachEvent("onpropertychange", function () {
          setStyle(el);
        });
        setStyle(el);
      }
      if (window.VBArray && window.addEventListener) {
        //IE9
        el.attachEvent("onkeydown", function () {
          var key = window.event.keyCode;
          if (key == 8 || key == 46) delayedResize(el);
        });
        el.attachEvent("oncut", function () {
          delayedResize(el);
        }); //处理粘贴
      }
    },
  },
};
</script>

<style>
.soundSDK {
  display: flex;
  align-items: center;
  height: 100%;
}
.metaContainer {
  position: fixed;
  right: 0 !important;
  top: 0;
  width: 450px !important;
  /*width: 100vw;
  height: 100%;*/
  z-index: 999;
}
.metaContainer * {
  box-sizing: border-box;
}
.metaname {
  font-size: 18px;
  color: rgba(51, 129, 255, 1);
  font-weight: 500;
  left: 20px !important;
  bottom: 10% !important;
  margin-bottom: 14px;
}
.metatalkbox {
  display: none;
  position: fixed;
  width: 340px;
  height: 133px;
  right: 10px;
  bottom: 613px;
  z-index: 999;
}
::v-deep .metatalkbox img,
::v-deep .metatalkbox video {
  max-width: 100%;
}
.metatalkbox_content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 27px 25px 16px 30px;
}
.metascroll_message {
  position: relative;
  width: 100%;
  height: 40px;
  line-height: 20px;
  overflow-y: auto;
  font-size: 14px;
  color: rgba(54, 131, 255, 1);
}
/* 定义滚动条的样式 */
.metascroll_message::-webkit-scrollbar {
  width: 4px; /* 滚动条宽度 */
}

/* 定义滚动条轨道的样式 */
.metascroll_message::-webkit-scrollbar-track {
  background-color: unset; /* 轨道背景色 */
}

/* 定义滚动条滑块的样式 */
.metascroll_message::-webkit-scrollbar-thumb {
  width: 4px;
  background: rgba(122, 173, 255, 0.5); /* 滑块背景色 */
  border-radius: 2px; /* 滑块圆角 */
}

/* 定义滚动条滑块在hover状态下的样式 */
.metascroll_message::-webkit-scrollbar-thumb:hover {
  background: rgba(122, 173, 255, 0.5); /* 滑块背景色 */
}
.metabtns {
  position: fixed;
  display: none;
  align-items: center;
  justify-content: space-between;
  bottom: 50px;
  right: 20px;
  height: 50px;
  z-index: 999;
}
.metabtn {
  cursor: pointer;
  font-size: 15px;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  box-sizing: border-box;
  background: #2d6aff;
  box-shadow: 0px 0px 10px #2d6aff;
  color: #fff;
  margin-right: 15px;
}

.metacloseBtn {
  display: none;
}
.metaeleWebGL {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  display: none;
  width: 100vw;
  /* height: 100vh; */
  z-index: 998;
  pointer-events: none;
}
.metasdkloading {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  text-align: center;
  line-height: 100vh;
  z-index: 9999;
}
.metaeleCanvas {
  display: none;
}
.metaeleRtcVideo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  pointer-events: none;
}
.metatalk {
  width: 100%;
  height: auto;
}
.metavoice {
  display: flex;
  width: 40px;
  height: 40px;
  background-color: #2d6aff;
  font-size: 12px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 999;
}

.scene3d-container {
  position: fixed;
  width: 340px;
  height: 344px;
  right: 450px;
  bottom: 80px;
  display: none;
  /* pointer-events: none; */
}

.sdkloading {
  right: 20px;
  bottom: 53px;
  position: fixed;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
}

.sdkloading .loader:before,
.sdkloading .loader:after {
  content: "";
  position: absolute;
  top: 0;
}

.sdkloading .loader:before,
.sdkloading .loader:after,
.sdkloading .loader {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.sdkloading .loader {
  position: relative;
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.sdkloading .loader:before {
  left: -15px;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.sdkloading .loader:after {
  left: 15px;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 20px 0 -10px #2d6aff;
  }
  40% {
    box-shadow: 0 20px 0 0 #2d6aff;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 20px 0 -10px #2d6aff;
  }
  40% {
    box-shadow: 0 20px 0 0 #2d6aff;
  }
}

.containerSdk {
  display: none;
  top: 0;
  right: 0;
  width: 450px;
  height: 100%;
  padding: 0px;
  box-sizing: border-box;
  position: absolute;
  box-shadow: -1px -1px 5px #dedede;
}
.container_box {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.headerSdk {
  background: #ffffff;
  height: 40px;
  color: #333;
  line-height: 34px;
  height: 34px;
  font-size: 16px;
  padding: 0 10px;
}
.footerSdk {
  width: 100%;
  min-height: 50px;
  /*height: 50px;*/
  background: #ffffff;
  padding: 5px 10px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}
.talkSendContainer {
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  background: rgb(230 239 255);
  border: 2px solid #5898ff;
  color: #4b78f2;
  border-radius: 10px;
  margin-right: 15px;
}
.talkSendContainer .sendInput {
  min-height: 20px;
  max-height: 300px;
  font-size: 16px;
  line-height: 20px;
  border-radius: 5px;
  padding: 0px 10px;
  width: 100%;
  border: 0px;
  background: none;
  color: #4b78f2;
  outline: 0;
  word-wrap: break-word;
  overflow-x: hidden;
  overflow-y: auto;
}
.talkSendContainer .sendInput:focus {
  border: 0px;
  outline: none;
}
.talkSendContainer .sendInput::placeholder {
  color: #4b78f2;
}
.talkSendContainer .btn {
  height: 40px;
  /* line-height: 48px; */
  width: 70px;
  margin-left: 10px;
  border: 0px;
  text-align: center;
  font-size: 24px;
  cursor: pointer;
  z-index: 999;
}
.talkSendContainer .btn svg {
  margin-top: 8px;
}
.talkSendContainer textarea {
  border: none;
}
.footerSdk span {
  width: 40px;
  height: 40px;
  background: rgba(51, 129, 255, 1);
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  right: 5px;
  color: #ffffff;
  border-radius: 50%;
  margin-top: 4px;
}
.footerSdk span:hover {
  color: #fff;
}
.footerSdk span svg {
  margin-top: 4px;
  width: 32px;
  height: 32px;
  color: #ffffff;
  font-size: 18px;
}

.contentSdk {
  flex: 1;
  font-size: 16px;
  width: 100%;
  overflow-y: auto;
  margin: 0 auto;
  padding: 10px 8px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.8);
}

.contentSdk li {
  margin-top: 10px;
  padding-left: 10px;
  width: 412px;
  display: block;
  clear: both;
  overflow: hidden;
  word-break: break-all;
}
.contentSdk li img {
  width: 40px;
  height: 40px;
  float: left;
  display: block;
}

.contentSdk li .imgleft {
  float: left;
  border-radius: 50%;
}
.contentSdk li .imgright {
  float: right;
  border-radius: 50%;
}
.contentSdk li .spanleft {
  float: left;
  background: #fff;
}
.contentSdk li .spanright {
  float: right;
  color: #fff;
  background: rgba(123, 152, 242, 1);
}

.contentSdk::-webkit-scrollbar {
  width: 4px;
}

.contentSdk::-webkit-scrollbar-track {
  background-color: unset;
}

.contentSdk::-webkit-scrollbar-thumb {
  width: 4px;
  background: rgba(122, 173, 255, 0.5);
  border-radius: 2px;
}

.contentSdk::-webkit-scrollbar-thumb:hover {
  background: rgba(122, 173, 255, 0.5);
}
.contentSdk li div {
  background: rgba(123, 152, 242, 1);
  padding: 10px;
  border-radius: 10px;
  float: left;
  margin: 0 10px;
  max-width: 310px;
  border: 1px solid #ccc;
  box-shadow: 0 0 3px #ccc;
  font-size: 14px;
}
.contentSdk li img.imgleft {
  float: left;
  border-radius: 50%;
}
.contentSdk li img.imgright {
  float: right;
  border-radius: 50%;
}
.contentSdk li div.spanleft {
  float: left;
  background: #fff;
}
.contentSdk li div.spanright {
  float: right;
  background: rgba(123, 152, 242, 1);
}
.contentSdk li p,
.contentSdk li span {
  font-size: 14px;
}
#metaContainer {
  width: 450px;
  right: 0px;
}
.contentSdk {
  flex: 1;
  font-size: 16px;
  width: 100%;
  overflow-y: auto;
  margin: 0 auto;
  padding: 10px 8px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.8);
}

.contentSdk li {
  margin-top: 10px;
  padding-left: 10px;
  width: 412px;
  display: block;
  clear: both;
  overflow: hidden;
}
.contentSdk li img {
  width: 40px;
  height: 40px;
  float: left;
  display: block;
}

.contentSdk li .imgleft {
  float: left;
  border-radius: 50%;
}
.contentSdk li .imgright {
  float: right;
  border-radius: 50%;
}
.contentSdk li .spanleft {
  float: left;
  background: #fff;
}
.contentSdk li .spanright {
  float: right;
  color: #fff;
  background: rgba(123, 152, 242, 1);
}

.contentSdk::-webkit-scrollbar {
  width: 4px;
}

.contentSdk::-webkit-scrollbar-track {
  background-color: unset;
}

.contentSdk::-webkit-scrollbar-thumb {
  width: 4px;
  background: rgba(122, 173, 255, 0.5);
  border-radius: 2px;
}

.contentSdk::-webkit-scrollbar-thumb:hover {
  background: rgba(122, 173, 255, 0.5);
}
.contentSdk li div {
  background: rgba(123, 152, 242, 1);
  padding: 10px;
  border-radius: 10px;
  float: left;
  margin: 0 10px;
  max-width: 310px;
  border: 1px solid #ccc;
  box-shadow: 0 0 3px #ccc;
  font-size: 14px;
}
.contentSdk li img.imgleft {
  float: left;
  border-radius: 50%;
}
.contentSdk li img.imgright {
  float: right;
  border-radius: 50%;
}
.contentSdk li div.spanleft {
  float: left;
  background: #fff;
}
.contentSdk li div.spanright {
  float: right;
  background: rgba(123, 152, 242, 1);
}
.contentSdk li p,
.contentSdk li span {
  font-size: 14px;
}
.flicker_span {
  animation: flicker 0.4s linear infinite;
}
@keyframes flicker {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
