<template>
  <svg
    t="1682388219936"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="1542"
    width="20"
    height="20">
    <path
      d="M211.2 32l567.466667 981.333333c4.266667 6.4 10.666667 10.666667 19.2 10.666667 17.066667 0 25.6-17.066667 19.2-32L249.6 10.666667c-6.4-6.4-12.8-10.666667-21.333333-10.666667-17.066667 0-25.6 17.066667-17.066667 32zM746.666667 512V234.666667c0-130.133333-104.533333-234.666667-234.666667-234.666667-70.4 0-132.266667 29.866667-174.933333 78.933333l343.466666 595.2C721.066667 633.6 746.666667 576 746.666667 512zM512 746.666667c19.2 0 38.4-2.133333 57.6-6.4L277.333333 234.666667v277.333333c0 130.133333 104.533333 234.666667 234.666667 234.666667zM810.666667 405.333333c-12.8 0-21.333333 8.533333-21.333334 21.333334v85.333333c0 78.933333-34.133333 151.466667-87.466666 200.533333l21.333333 36.266667c66.133333-61.866667 108.8-149.333333 108.8-247.466667V426.666667c0-12.8-8.533333-21.333333-21.333333-21.333334z"
      fill="#ffffff"
      p-id="1543"></path>
    <path
      d="M682.666667 981.333333h-149.333334v-149.333333c27.733333-2.133333 53.333333-8.533333 78.933334-17.066667l-21.333334-36.266666c-29.866667 8.533333-59.733333 12.8-93.866666 10.666666-149.333333-6.4-262.4-136.533333-262.4-285.866666V426.666667c0-12.8-8.533333-21.333333-21.333334-21.333334s-21.333333 8.533333-21.333333 21.333334v85.333333c0 168.533333 132.266667 307.2 298.666667 320v149.333333h-149.333334c-12.8 0-21.333333 8.533333-21.333333 21.333334s8.533333 21.333333 21.333333 21.333333h341.333334c12.8 0 21.333333-8.533333 21.333333-21.333333s-8.533333-21.333333-21.333333-21.333334z"
      fill="#ffffff"
      p-id="1544"></path>
  </svg>
</template>
<script>
export default {
  name: "voicedis",
  computed: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {},
};
</script>
