<template>
  <svg
    t="1689845897580"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="6095"
    id="mx_n_1689845897581"
    width="60%"
    height="60%"
    class="icon">
    <path
      d="M82.715691 492.766628l-3.269128-1.08971c-38.684687-12.531659-65.382569-48.492072-68.106843-89.356177L10.794866 394.14792c0.544855-46.312653 31.05672-86.087049 74.100244-97.528999L870.575647 9.480472c38.139832-17.980206 82.817921-9.807385 112.784931 20.159626 29.967011 30.511865 37.594977 76.824518 21.79419 111.150367l-282.779611 808.019581-1.089709 4.358838a99.163563 99.163563 0 0 1-86.631904 70.286261l-8.172821 0.544855c-45.222943 0-83.90763-31.05672-95.894435-74.645099l-124.22688-330.181973z m830.358626-390.66085l-4.358838 1.634565-792.218794 289.317867-5.993402 2.179419 5.993402 1.634564 322.554007 125.861445 133.489412-136.213685a47.947217 47.947217 0 0 1 67.017133-2.179419c19.614771 17.435352 22.339044 46.857508 6.538257 67.561988l-4.358838 4.903693-136.75854 140.572523 119.323188 317.650314 2.179419 7.083111 1.634564-5.448547z"
      p-id="6096"
      fill="#4f7eff"></path>
  </svg>
</template>
<script>
export default {
  name: "sendbtn",
  computed: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {},
};
</script>
