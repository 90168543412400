<template>
  <div class="waveform dac">
    <div class="bar" v-for="(item, index) in bar" :key="index"></div>
  </div>
</template>
<script>
let bars = null;
export default {
  name: "waveform",
  computed: {},
  data() {
    return {
      bar: 30,
      timer: null,
    };
  },
  created() {},
  mounted() {
    bars = document.querySelector(".waveform").querySelectorAll(".bar");
    this.timer = setInterval(function () {
      for (let i = 0; i < bars.length; i++) {
        let height = Math.random() * 80;
        if (i < bars.length * 0.3 || i > bars.length * 0.7) {
          height /= 3;
        }
        if (i < bars.length * 0.1 || i > bars.length * 0.9) {
          height /= 5;
        }
        bars[i].style.height = height + "%";
      }
    }, 100);
  },
  destroyed() {
    clearInterval(this.timer);
    this.timer = null;
    for (let i = 0; i < bars.length; i++) {
      bars[i].style.height = 0;
    }
  },
  watch: {},
  methods: {},
};
</script>
<style>
.waveform {
  display: flex;
  align-items: center;
  height: 100%;
  width: 80%;
  justify-content: space-between;
}
.waveform .bar {
  width: 2px;
  height: 0;
  background: #fff;
  transition: all 200ms;
}
</style>
