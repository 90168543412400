const uuid = require("./uuid").uuid;

class NlsClient {
  constructor(config) {
    if (!config || !config.url || !config.appkey) {
      throw new Error("invalid config!");
    }
    this._config = config;
  }

  start(onmessage, onclose) {
    if (typeof onmessage !== "function") {
      throw new Error("expect function onmessage");
    }
    if (typeof onclose != "function") {
      throw new Error("expect function onclose");
    }
    this.ws = new WebSocket(this._config.url);
    this.ws.onmessage = (res) => {
      if (typeof res.data === "string") {
        onmessage(res.data, false);
      } else {
        onmessage(res.data, true);
      }
    };
    this.ws.onClose = (code, reason) => {
      onclose(code, reason);
    };
    return new Promise((resolve, reject) => {
      this.ws.onopen = (res) => {
        resolve(res);
      };
      this.ws.onerror = (errMsg) => {
        reject(errMsg);
      };
    });
  }

  send(data, isBinary) {
    if (this.ws == null) {
      return;
    }
    // console.log(typeof data, 0);
    this.ws.readyState == 1 ? this.ws.send(data) : "";
  }

  uuid() {
    return uuid(true);
  }

  shutdown() {
    if (this.ws == null) {
      return;
    }
    this.ws.close();
  }

  defaultContext() {
    return {
      sdk: {
        name: "nls-wx-sdk",
        version: "0.0.1",
        language: "wxjs",
      },
    };
  }
}
module.exports = NlsClient;
