<template>
  <svg
    t="1682388877699"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="2009"
    width="20"
    height="20">
    <path
      d="M810.666667 405.333333c-12.8 0-21.333333 8.533333-21.333334 21.333334v85.333333c0 157.866667-132.266667 285.866667-292.266666 277.333333-149.333333-6.4-262.4-136.533333-262.4-285.866666V426.666667c0-12.8-8.533333-21.333333-21.333334-21.333334s-21.333333 8.533333-21.333333 21.333334v85.333333c0 168.533333 132.266667 307.2 298.666667 320v149.333333h-149.333334c-12.8 0-21.333333 8.533333-21.333333 21.333334s8.533333 21.333333 21.333333 21.333333h341.333334c12.8 0 21.333333-8.533333 21.333333-21.333333s-8.533333-21.333333-21.333333-21.333334h-149.333334v-149.333333c168.533333-10.666667 298.666667-157.866667 298.666667-328.533333V426.666667c0-12.8-8.533333-21.333333-21.333333-21.333334zM512 746.666667c130.133333 0 234.666667-104.533333 234.666667-234.666667V234.666667c0-130.133333-104.533333-234.666667-234.666667-234.666667S277.333333 104.533333 277.333333 234.666667v277.333333c0 130.133333 104.533333 234.666667 234.666667 234.666667z"
      fill="#ffffff"
      p-id="2010"></path>
  </svg>
</template>
<script>
export default {
  name: "voiceactive",
  computed: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {},
};
</script>
